var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addLedgerDialog-page" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "重新分账",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "550px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "140px",
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "渠道：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.channelName))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "业务类型：" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("getOrderBusinessType")(
                            _vm.ruleForm.orderBusinessType
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "分账策略：" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("getLedgerReceiveStrategy")(
                            _vm.ruleForm.ledgerReceiveStrategy
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm.ruleForm.ledgerReceiveStrategy
                    ? _c("el-form-item", { attrs: { label: "线路：" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.ruleForm.lineName))]),
                      ])
                    : _vm._e(),
                  _c("el-form-item", { attrs: { label: "分账失败原因：" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("getResultRemarks")(
                            _vm.ruleForm.ledgerResultRemarks
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "收款方：" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.ruleForm.ledgerReceiveName)),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "分账模式：" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("getLedgerReceiveModel")(
                            _vm.ruleForm.ledgerReceiveModel
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "订单金额：" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.ruleForm.ledgerPrices) + "元"),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.ruleForm.ledgerReceiveModel == 1
                            ? "分账比例："
                            : "分账金额：",
                        prop: "ledgerReceiveFee",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          maxlength:
                            _vm.ruleForm.ledgerReceiveModel == 1 ? "2" : "5",
                          placeholder:
                            _vm.ruleForm.ledgerReceiveModel == 1
                              ? "请输入1-30的整数"
                              : "请输入正数",
                        },
                        model: {
                          value: _vm.ruleForm.ledgerReceiveFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "ledgerReceiveFee", $$v)
                          },
                          expression: "ruleForm.ledgerReceiveFee",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.ruleForm.ledgerReceiveModel == 1 ? "%" : "元"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.ruleForm.ledgerReceiveModel == 1
                    ? _c("el-form-item", { attrs: { label: "分账收入：" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.ledgerIncome))]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.saveFun } },
                    [_vm._v("保 存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }