<template>
  <div class="addLedgerDialog-page">
    <el-dialog
      v-if="dialogVisible"
      title="重新分账"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="渠道：">
          <span>{{ ruleForm.channelName }}</span>
        </el-form-item>
        <el-form-item label="业务类型：">
          <span>{{ ruleForm.orderBusinessType | getOrderBusinessType }}</span>
        </el-form-item>
        <el-form-item label="分账策略：">
          <span>{{
            ruleForm.ledgerReceiveStrategy | getLedgerReceiveStrategy
          }}</span>
        </el-form-item>
        <el-form-item v-if="ruleForm.ledgerReceiveStrategy" label="线路：">
          <span>{{ ruleForm.lineName }}</span>
        </el-form-item>
        <el-form-item label="分账失败原因：">
          <span>{{ ruleForm.ledgerResultRemarks | getResultRemarks }}</span>
        </el-form-item>
        <el-form-item label="收款方：">
          <span>{{ ruleForm.ledgerReceiveName }}</span>
        </el-form-item>
        <el-form-item label="分账模式：">
          <span>{{ ruleForm.ledgerReceiveModel | getLedgerReceiveModel }}</span>
        </el-form-item>
        <el-form-item label="订单金额：">
          <span>{{ ruleForm.ledgerPrices }}元</span>
        </el-form-item>
        <el-form-item
          :label="
            ruleForm.ledgerReceiveModel == 1 ? '分账比例：' : '分账金额：'
          "
          prop="ledgerReceiveFee"
        >
          <el-input
            v-model="ruleForm.ledgerReceiveFee"
            clearable
            :maxlength="ruleForm.ledgerReceiveModel == 1 ? '2' : '5'"
            :placeholder="
              ruleForm.ledgerReceiveModel == 1
                ? '请输入1-30的整数'
                : '请输入正数'
            "
          ></el-input>
          <span style="margin-left: 10px">{{
            ruleForm.ledgerReceiveModel == 1 ? "%" : "元"
          }}</span>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.ledgerReceiveModel == 1"
          label="分账收入："
        >
          <span>{{ ledgerIncome }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveFun">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateIntercityLedgerOrderAPI } from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {
    channelList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let reg1 = /^([12][0-9]|30|[1-9])$/; //1-30整数
    let reg2 = /^[1-9]\d*$/; //正整数
    var validateNumber = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            this.ruleForm.ledgerReceiveModel == 1
              ? "请输入1-30的整数"
              : "请输入正数"
          )
        );
      } else if (this.ruleForm.ledgerReceiveModel == 1 && !reg1.test(value)) {
        callback(new Error("请输入1-30的整数"));
      }
      // else if (this.ruleForm.ledgerReceiveModel == 2 && !reg2.test(value)) {
      //   callback(new Error("请输入正整数"));
      // }
      else {
        callback();
      }
    };
    return {
      dialogVisible: "",
      ruleForm: {
        channelName: null,
        orderBusinessType: null,
        ledgerReceiveStrategy: null,
        lineName: null,
        ledgerReceiveName: null,
        ledgerReceiveModel: 1,
        ledgerReceiveFee: "",
        ledgerPrices: null,
      },
      rules: {
        ledgerReceiveFee: [
          { required: true, validator: validateNumber, trigger: "blur" },
        ],
      },
      ledgerReceiveModelList: [
        { label: "按比例分账", value: 1 },
        { label: "按固定金额分账", value: 2 },
      ],
    };
  },
  filters: {
    getOrderBusinessType(v) {
      const arr = [
        { name: "城际专车", code: 1 },
        { name: "汽车票", code: 2 },
        { name: "包车", code: 4 },
        { name: "租车", code: 5 },
      ];
      return arr.find((item) => item.code == v)?.name;
    },
    getLedgerReceiveStrategy(v) {
      const arr = [
        { name: "统一分账", code: 0 },
        { name: "按线路分账", code: 1 },
      ];
      return arr.find((item) => item.code == v)?.name;
    },
    getLedgerReceiveModel(v) {
      const arr = [
        { name: "按比例", code: 1 },
        { name: "按固定金额", code: 2 },
      ];
      return arr.find((item) => item.code == v)?.name;
    },
    getResultRemarks(v) {
      return JSON.parse(v).message;
    },
  },
  computed: {
    ledgerIncome() {
      return (
        (
          this.ruleForm.ledgerPrices *
          (this.ruleForm.ledgerReceiveFee / 100)
        ).toFixed(2) + "元"
      );
    },
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.ruleForm = this.$options.data().ruleForm;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    openDialogFun(row) {
      Object.assign(this.ruleForm, row);
      this.dialogVisible = true;
    },
    /**
     * @description 保存
     */
    saveFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            ledgerOrderId: this.ruleForm.ledgerOrderId,
            ledgerReceiveFee: this.ruleForm.ledgerReceiveFee,
          };
          updateIntercityLedgerOrderAPI(params).then((res) => {
            if (res.code === "SUCCESS" && res.data) {
              this.dialogVisible = false;
              this.$parent.renderData();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.addLedgerDialog-page {
  .el-input {
    width: 50%;
  }
}
</style>
