<template>
  <div ref="billOrder" class="billOrder">
    <el-tabs v-model="active" type="card" @tab-click="handleClick">
      <el-tab-pane label="分账概览" name="3">
        <div ref="explain" class="explain">
          <header><i class="el-icon-warning"></i> 数据说明：</header>
          <ol>
            <li>实际分账金额=分账金额-分账回退金额；</li>
            <li>
              分账金额：订单支付金额*订单所属业务的分账比例，支付成功后自动分账，分账完成后计入；
            </li>
            <li>
              分账回退金额：退款金额*订单所属业务的分账比例，订单退款金额不参与分账，退款成功后自动回退部分分账金额（按退款金额计算），分账回退完成后计入。
            </li>
          </ol>
        </div>
        <div ref="form3" class="form-area">
          <el-form
            :inline="true"
            :model="form3"
            class="demo-form-inline"
            label-position="right"
            label-width="98px"
          >
            <el-form-item label="交易时间：" class="code-time-range">
              <el-date-picker
                v-model="ledgerTime3"
                type="daterange"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="订单业务类型：">
              <el-select
                v-model="form3.orderBusinessType"
                clearable
                placeholder="请选择订单业务类型"
                @clear="form.orderBusinessType = null"
              >
                <el-option
                  v-for="item in orderBusinessTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="renderData3"
                >查询</el-button
              >
              <el-button
                v-if="listFind('概览导出')"
                type="primary"
                size="small"
                @click="exportFun3"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          ref="table"
          :table-data="tableData3"
          :title-name="titleName3"
          :show-summary="true"
          :table-height="TableHeight3"
          :summary-method="getSummaries"
          :isExpandChange="true"
        >
        </Table>
      </el-tab-pane>
      <el-tab-pane label="分账" name="1">
        <div ref="form" class="form-area">
          <el-form
            :inline="true"
            :model="form"
            class="demo-form-inline"
            label-position="right"
            label-width="98px"
          >
            <el-form-item label="分账时间：" class="code-time-range">
              <el-date-picker
                v-model="ledgerTime"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="收款方名称：">
              <el-input
                v-model.trim="form.ledgerReceiveName"
                placeholder="请输入收款方名称"
                clearable
                @clear="form.ledgerReceiveName = null"
              ></el-input>
            </el-form-item>
            <el-form-item label="收款方账号：">
              <el-input
                v-model.trim="form.ledgerReceiveId"
                placeholder="请输入收款方账号"
                clearable
                @clear="form.ledgerReceiveId = null"
              ></el-input>
            </el-form-item>
            <el-form-item label="渠道：">
              <el-select
                v-model="form.channelNo"
                clearable
                placeholder="请选择渠道"
                @clear="form.channelNo = null"
              >
                <el-option
                  v-for="item in channelList"
                  :key="item.channelNo"
                  :label="item.channelName"
                  :value="item.channelNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单号：">
              <el-input
                v-model.trim="form.orderId"
                placeholder="请输入订单号"
                clearable
                @clear="form.orderId = null"
              ></el-input>
            </el-form-item>
            <el-form-item label="分账状态：">
              <el-select
                v-model="form.ledgerOrderStatus"
                clearable
                placeholder="请选择分账状态"
                @clear="form.ledgerOrderStatus = null"
              >
                <el-option
                  v-for="item in ledgerOrderStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单业务类型：">
              <el-select
                v-model="form.orderBusinessType"
                clearable
                placeholder="请选择订单业务类型"
                @clear="form.orderBusinessType = null"
              >
                <el-option
                  v-for="item in orderBusinessTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="queryFun"
                >查询</el-button
              >
              <el-button
                v-if="listFind('导出')"
                type="primary"
                size="small"
                @click="exportFun"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          :table-data="tableData"
          :title-name="titleName"
          :table-height="TableHeight"
          :operation="true"
          operation-width="100"
        >
          <template slot-scope="{ scopeRow }">
            <div>
              <el-button
                v-if="
                  listFind('重新分账') &&
                  scopeRow.ledgerIncome > 0 &&
                  scopeRow.ledgerOrderStatus == 0
                "
                type="text"
                size="small"
                sort="primary"
                @click="resetRate(scopeRow.ledgerOrderId)"
                >重新分账</el-button
              >
            </div>
          </template>
        </Table>
        <div class="footer">
          <p style="font-size: 15px">分账总额：{{ price }}元</p>
          <el-pagination
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="total"
            :page-size="form.pageSize"
            :current-page="form.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="分账回退" name="2">
        <div ref="form2" class="form-area">
          <el-form
            :inline="true"
            :model="form2"
            class="demo-form-inline"
            label-position="right"
            label-width="98px"
          >
            <el-form-item label="回退时间：" class="code-time-range">
              <el-date-picker
                v-model="ledgerTime2"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="回退状态：">
              <el-select
                v-model="form2.refundLedgerStatus"
                clearable
                placeholder="请选择回退状态"
                @clear="form.refundLedgerStatus = null"
              >
                <el-option
                  v-for="item in refundLedgerStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单业务类型：">
              <el-select
                v-model="form2.orderBusinessType"
                clearable
                placeholder="请选择订单业务类型"
                @clear="form.orderBusinessType = null"
              >
                <el-option
                  v-for="item in orderBusinessTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="渠道：">
              <el-select
                v-model="form2.channelNo"
                clearable
                placeholder="请选择渠道"
                @clear="form.channelNo = null"
              >
                <el-option
                  v-for="item in channelList"
                  :key="item.channelNo"
                  :label="item.channelName"
                  :value="item.channelNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单号：">
              <el-input
                v-model.trim="form2.orderId"
                placeholder="请输入订单号"
                clearable
                @clear="form.orderId = null"
              ></el-input>
            </el-form-item>
            <el-form-item label="子订单号：">
              <el-input
                v-model.trim="form2.childOrderId"
                placeholder="请输入子订单号"
                clearable
                @clear="form.childOrderId = null"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="queryFun2"
                >查询</el-button
              >
              <el-button
                v-if="listFind('回退导出')"
                type="primary"
                size="small"
                @click="exportFun2"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          :table-data="tableData2"
          :title-name="titleName2"
          :table-height="TableHeight2"
        ></Table>
        <div class="footer">
          <p>分账回退总额：{{ price2 }}元</p>
          <el-pagination
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="total2"
            :page-size="form2.pageSize"
            :current-page="form2.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <subLedgerDialog
      ref="subLedgerDialogRef"
      :channel-list="channelList"
    ></subLedgerDialog>
  </div>
</template>

<script>
import { formatDate } from "@/tools/getTime.js";
import subLedgerDialog from "../scheduling/subLedgerDialog/index.vue";
import {
  exportIntercityOrderPageAPI,
  queryKkLedgerOrderRefundPageAPI,
  queryIntercityOrderPageAPI,
  exportKkLedgerOrderRefundAPI,
  queryChannelListAPI,
  queryLedgerOrderInfoAPI,
  queryTotalLedgerIncomeAPI,
  queryKkLedgerRefundLedgerFeeAPI,
  queryKkLedgerStatisticsList,
  exportKkLedgerStatistics,
} from "@/api/lib/api.js";
import moment from "moment";
export default {
  components: {
    subLedgerDialog,
  },
  data() {
    return {
      active: "3",
      form: {
        currentPage: 1,
        pageSize: 10,
        ledgerReceiveName: null,
        channelNo: null,
        ledgerOrderStatus: null,
        orderId: null,
        ledgerReceiveId: null,
        ledgerTimeEnd: null,
        ledgerTimeStart: null,
        orderBusinessType: null,
      },
      form2: {
        currentPage: 1,
        pageSize: 10,
        channelNo: null,
        orderId: null,
        childOrderId: null,
        ledgerTimeEnd: null,
        ledgerTimeStart: null,
        refundLedgerStatus: null,
        orderBusinessType: null,
      },
      form3: {
        statisticsEndTime: null,
        statisticsStartTime: null,
        orderBusinessType: null,
      },
      pickerOptions: {
        disabledDate: (time) => {
          if (!this.minDate)
            return time.getTime() > Date.now() - 24 * 3600 * 1000;
          else {
            const daysInMonth = [
              30, 27, 30, 29, 30, 29, 30, 30, 29, 30, 29, 30,
            ];
            const cur = new Date().getFullYear();
            if ((cur % 4 === 0 && cur % 100 !== 0) || cur % 400 === 0) {
              daysInMonth[1] = 28;
            }
            const date =
              this.minDate.getTime() +
              daysInMonth[this.minDate.getMonth()] * 24 * 3600 * 1000;
            if (time.getTime() > date) {
              return time.getTime() > date;
            } else {
              return time.getTime() > Date.now() - 24 * 3600 * 1000;
            }
          }
        },
        onPick: (date) => {
          console.log(date);
          if (date.minDate) {
            this.minDate = date.minDate;
          }
        },
      },
      minDate: "",
      ledgerTime: "",
      ledgerTime2: "",
      ledgerTime3: "",
      ledgerOrderStatusList: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "待分账",
          value: -1,
        },
        {
          label: "分账完成",
          value: 1,
        },
        {
          label: "分账失败",
          value: 0,
        },
      ],
      orderBusinessTypeList: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "城际专车订单",
          value: 1,
        },
        {
          label: "汽车票订单",
          value: 2,
        },
        {
          label: "包车",
          value: 4,
        },
        {
          label: "租车",
          value: 5,
        },
      ],
      refundLedgerStatusList: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "待回退",
          value: -1,
        },
        {
          label: "已回退",
          value: 1,
        },
        {
          label: "回退失败",
          value: 0,
        },
      ],
      channelList: [],
      exportForm: {},
      TableHeight: 0,
      TableHeight2: 0,
      TableHeight3: 0,
      tableData: [],
      tableData2: [],
      tableData3: [],
      titleName: [
        {
          title: "分账时间",
          props: "ledgerTime",
          width: 200,
          SpecialJudgment: (res) => {
            if (res === "1970-01-01 08:00:00") {
              return "-";
            } else {
              return res;
            }
          },
        },
        {
          title: "分账金额",
          props: "ledgerIncome",
          SpecialJudgment: (res) => res + "元",
        },
        {
          title: "分账状态",
          props: "ledgerOrderStatus",
          SpecialJudgment: (res) => {
            return this.ledgerOrderStatusList.find((item) => item.value == res)
              ?.label;
          },
        },
        {
          title: "订单业务类型",
          width: 200,
          props: "orderBusinessType",
          SpecialJudgment: (res) => {
            return this.orderBusinessTypeList.find((item) => item.value == res)
              ?.label;
          },
        },
        {
          title: "订单金额",
          props: "ledgerPrices",
          SpecialJudgment: (res) => res + "元",
        },
        {
          title: "分账模式",
          width: 200,
          SpecialJudgment: (res) => {
            return res.ledgerReceiveModel == 1
              ? `按比例（${res.ledgerReceiveFee}%）`
              : `按固定金额（${res.ledgerReceiveFee}元）`;
          },
        },
        {
          title: "分账流水号",
          props: "ledgerOrderTransaction",
          width: 120,
          SpecialJudgment: (res) => {
            if (!res) return "-";
            else return res;
          },
        },
        {
          title: "订单号",
          props: "orderId",
          width: 150,
        },
        {
          title: "收款方名称",
          props: "ledgerReceiveName",
          width: 200,
        },
        {
          title: "收款方账号",
          props: "ledgerReceiveId",
          width: 120,
        },
      ],
      titleName2: [
        {
          title: "回退时间",
          props: "refundLedgerTime",
          width: 200,
          SpecialJudgment: (res) => {
            if (res === "1970-01-01 08:00:00") {
              return "-";
            } else {
              return res;
            }
          },
        },
        {
          title: "回退金额",
          props: "refundLedgerFee",
          SpecialJudgment: (res) => res + "元",
        },
        {
          title: "回退状态",
          props: "refundLedgerStatus",
          SpecialJudgment: (res) =>
            this.refundLedgerStatusList.find((e) => e.value == res).label,
        },
        {
          title: "订单业务类型",
          width: 200,
          props: "orderBusinessType",
          SpecialJudgment: (res) => {
            return this.orderBusinessTypeList.find((item) => item.value == res)
              ?.label;
          },
        },
        {
          title: "退款金额",
          props: "orderRefundFee",
          SpecialJudgment: (res) => res + "元",
        },
        {
          title: "回退单号",
          props: "refundChildOrderId",
          width: 150,
        },
        {
          title: "订单号",
          props: "orderId",
          width: 150,
        },
        {
          title: "子订单号",
          props: "childOrderId",
          width: 150,
        },
        {
          title: "支出商户号",
          props: "ledgerReceiveName",
          width: 200,
        },
      ],
      titleName3: [
        {
          title: "交易时间",
          props: "statisticsDay",
          SpecialJudgment: (res) => res.slice(0, 10),
        },
        {
          title: "分账订单金额",
          props: "ledgerOrderAmount",
          SpecialJudgment: (res) => res.toFixed(2),
        },
        {
          title: "分账订单数",
          props: "ledgerOrderCount",
        },
        {
          title: "分账金额",
          props: "ledgerAmount",
          SpecialJudgment: (res) => res.toFixed(2),
        },
        {
          title: "退款金额",
          props: "refundAmount",
          SpecialJudgment: (res) => res.toFixed(2),
        },
        {
          title: "退款订单数",
          props: "refundOrderCount",
        },
        {
          title: "分账回退金额",
          props: "ledgerRollbackAmount",
          SpecialJudgment: (res) => res.toFixed(2),
        },
        {
          title: "实际分账金额",
          props: "ledgerActualAmount",
          SpecialJudgment: (res) => res.toFixed(2),
        },
      ],
      total: 0,
      total2: 0,
      price: 0,
      price2: 0,
    };
  },
  mounted() {
    const date1 = moment(new Date()).subtract(1, "months").format("YYYY-MM-DD");
    const date2 = moment(new Date()).subtract(1, "day").format("YYYY-MM-DD");
    this.ledgerTime3 = [date1, date2];
    this.renderData3();
    this.getQueryChannelList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    handleClick() {
      const date1 = moment(new Date())
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      const date2 = moment(new Date()).format("YYYY-MM-DD");
      this.computeHeight();
      if (this.active == "1") {
        this.ledgerTime = [date1 + " 00:00:00", date2 + " 23:59:59"];
        this.form = {
          currentPage: 1,
          pageSize: 10,
          ledgerReceiveName: null,
          channelNo: null,
          ledgerOrderStatus: null,
          orderId: null,
          ledgerReceiveId: null,
          orderBusinessType: null,
          ledgerTimeStart: this.ledgerTime[0],
          ledgerTimeEnd: this.ledgerTime[1],
        };
        this.renderData();
      } else if (this.active == "2") {
        this.ledgerTime2 = [date1 + " 00:00:00", date2 + " 23:59:59"];
        this.form2 = {
          currentPage: 1,
          pageSize: 10,
          channelNo: null,
          orderId: null,
          childOrderId: null,
          refundLedgerStatus: null,
          orderBusinessType: null,
          ledgerTimeStart: this.ledgerTime2[0],
          ledgerTimeEnd: this.ledgerTime2[1],
        };
        this.renderData2();
      } else {
        const date3 = moment(new Date())
          .subtract(1, "day")
          .subtract(1, "months")
          .format("YYYY-MM-DD");
        const date4 = moment(new Date())
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        this.ledgerTime3 = [date3, date4];
        this.renderData3();
      }
    },
    //获取渠道数据
    getQueryChannelList() {
      queryChannelListAPI({ status: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.channelList.unshift({
            channelName: "全部",
            channelNo: null,
          });
        }
      });
    },
    queryFun() {
      if (this.ledgerTime) {
        this.form.ledgerTimeStart = this.ledgerTime[0];
        this.form.ledgerTimeEnd = this.ledgerTime[1];
      } else {
        this.form.ledgerTimeStart = null;
        this.form.ledgerTimeEnd = null;
      }
      this.form.currentPage = 1;
      this.exportForm = this.deepClone(this.form);
      this.renderData();
    },
    queryFun2() {
      if (this.ledgerTime2) {
        this.form2.ledgerTimeStart = this.ledgerTime2[0];
        this.form2.ledgerTimeEnd = this.ledgerTime2[1];
      } else {
        this.form2.ledgerTimeStart = null;
        this.form2.ledgerTimeEnd = null;
      }
      this.form2.currentPage = 1;
      this.exportForm = this.deepClone(this.form2);
      this.renderData2();
    },
    exportFun() {
      exportIntercityOrderPageAPI(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "分账 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    exportFun2() {
      exportKkLedgerOrderRefundAPI(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "分账回退 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    exportFun3() {
      exportKkLedgerStatistics(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "分账概览 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      queryIntercityOrderPageAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list || [];
          this.tableData = this.tableData.map((item) => {
            item.ledgerTime = formatDate(new Date(item.ledgerTime));
            return item;
          });
          this.total = res.data.total;
        }
      });
      queryTotalLedgerIncomeAPI({ ...this.form, pageSize: 10000 }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.price = res.data.toFixed(2);
          }
        }
      );
    },
    renderData2() {
      queryKkLedgerOrderRefundPageAPI(this.form2).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data.list || [];
          this.tableData2 = this.tableData2.map((item) => {
            item.ledgerTime = formatDate(new Date(item.ledgerTime));
            return item;
          });
          this.total2 = res.data.total;
        }
      });
      queryKkLedgerRefundLedgerFeeAPI({ ...this.form2, pageSize: 10000 }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.price2 = res.data.toFixed(2);
          }
        }
      );
    },
    renderData3() {
      this.form3.statisticsStartTime = this.ledgerTime3[0] + " 00:00:00";
      this.form3.statisticsEndTime = this.ledgerTime3[1] + " 23:59:59";
      this.exportForm = this.deepClone(this.form3);
      queryKkLedgerStatisticsList(this.form3).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData3 = res.data;
          this.tableData3 = this.tableData3.map((item) => {
            item.ledgerTime = formatDate(new Date(item.ledgerTime));
            return item;
          });
        }
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      const props = [
        "ledgerOrderAmount",
        "ledgerOrderCount",
        "ledgerAmount",
        "refundAmount",
        "refundOrderCount",
        "ledgerRollbackAmount",
        "ledgerActualAmount",
      ];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => item[props[index - 1]]);
        sums[index] = values.reduce((prev, curr) => {
          return prev + curr;
        }, 0);
        if ([1, 3, 4, 6, 7].includes(index)) {
          sums[index] = sums[index].toFixed(2);
        }
      });
      return sums;
    },
    //重新分账
    resetRate(ledgerOrderId) {
      queryLedgerOrderInfoAPI(ledgerOrderId).then((res) => {
        if (res.code == "SUCCESS") {
          this.$refs.subLedgerDialogRef.openDialogFun(res.data);
        }
      });
    },
    computeHeight() {
      const wholeHeight = this.$refs.billOrder.clientHeight;
      if (this.active == "1") {
        this.$nextTick(() => {
          const form = this.$refs.form.clientHeight;
          this.TableHeight = wholeHeight - form - 150 + "px";
        });
      } else if (this.active == "2") {
        this.$nextTick(() => {
          const form2 = this.$refs.form2.clientHeight;
          this.TableHeight2 = wholeHeight - form2 - 150 + "px";
        });
      } else {
        this.$nextTick(() => {
          const form3 = this.$refs.form3.clientHeight;
          const explain = this.$refs.explain.clientHeight;
          this.TableHeight3 = wholeHeight - form3 - explain - 140 + "px";
        });
      }
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      if (this.active == "1") {
        this.form.currentPage = val;
        this.renderData();
      } else {
        this.form2.currentPage = val;
        this.renderData2();
      }
    },
    // 分页change方法
    onSizeChange(val) {
      if (this.active == "1") {
        this.form.pageSize = val;
        this.form.currentPage = 1;
        this.renderData();
      } else {
        this.form2.pageSize = val;
        this.form2.currentPage = 1;
        this.renderData2();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.billOrder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin: 16px 0;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
  /deep/.el-table__footer-wrapper {
    margin-top: 0;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    p {
      margin-top: 25px;
      font-size: 15px;
    }
  }
}
</style>
